.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  min-width: 0;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 100px;
  min-width: 0;
}
