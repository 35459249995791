.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.headerHomepageDefault:global(.__wab_instance) {
  max-width: 100%;
}
.headerHomepageDefaultglobal__040420231000042820231000_override:global(
    .__wab_instance
  ) {
  display: none;
}
.caseyHeader:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.caseyHeaderglobal__040420231000042820231000_override:global(.__wab_instance) {
  display: flex;
}
